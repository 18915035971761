<template>
  <v-row no-gutters class="fill-height align-center justify-center">
    <v-btn
      small
      fab
      depressed
      @click="show"
      icon
      class="mr-1"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-icon>
        mdi-arrow-left-bold-circle-outline
      </v-icon>
    </v-btn>
    No tool selected
  </v-row>
</template>

<script>
export default {
  name: "NoTool",
  methods: {
    show() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$emit("show");
      }
    }
  }
};
</script>
